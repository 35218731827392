import { Mixins, Component } from "vue-property-decorator";

import TransactionsMixin from "../../common/mixins/transactions.mixin";
import TransactionMixin from "../../common/mixins/transaction.mixin";

@Component
export default class MobileTransactions extends Mixins(
  TransactionsMixin,
  TransactionMixin
) {
  private get topBarSuccessButton() {
    return {
      title: this.$vuetify.lang.t("$vuetify.dashboard.search"),
      loading: this.loadingTransactionList,
      disabled: this.disabledSearch,
      listeners: {
        click: this.refreshTransactions,
      },
    };
  }

  private created() {
    this.createdHook();
  }

  private mounted() {
    this.mountedHook();

    this.$watch(
      () => {
        return this.rangeDate.join("-");
      },
      () => {
        this.refreshTransactions();
      }
    );
  }
}
